import { useState, useEffect } from "react";
import publicAxios from "../../axios/publicAxios";

export const useFetchBlogs = (
  category,
  page,
  dateRange,
  dateFun,
  state,
  reset
) => {
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [noBlogData, setNoBlogData] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const blog_category = {
    current_affair: "1",
    editorial: "2",
    job: "3",
    result: "4",
  };

  useEffect(() => {
    setLoading(true);
    setError(false);

    publicAxios
      .get(getBlogURL(category, blog_category), {
        params: {
          page: page,
          start_date: dateRange
            ? dateRange?.start_date?.format("YYYY-MM-DD")
            : "",
          end_date: dateRange ? dateRange?.end_date?.format("YYYY-MM-DD") : "",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          let resp = res.data;
          if (resp.data.length > 0) {
            if (reset) {
              setBlogData([...resp.data]);
            }

            setTotalPages(resp.total_page);

            const alreadyBlogIds = blogData.map((blog) => blog.id);

            console.log();

            const notInState = resp.data.filter(
              (post) => !alreadyBlogIds.includes(post.id)
            );

            setBlogData((blogData) => [...blogData, ...(notInState || [])]);
          } else if (resp.data.length === 0 && page === 1) {
            setNoBlogData(true);
          }
          setLoading(false);
          // console.info(resp);
        }
      })
      .catch((e) => {
        setError(true);
        console.info("error", e);
      });
  }, [page, category, dateFun, state, reset]);

  return [blogData, totalPages, loading, noBlogData, error];
};

const getBlogURL = (category, blog_category) => {
  let blogCategory = category;
  switch (category) {
    case "CurrentAffair":
      blogCategory = blog_category.current_affair;
      break;
    case "Editorial":
      blogCategory = blog_category.editorial;
      break;
    case "Job":
      blogCategory = blog_category.job;
      break;
    case "Result":
      blogCategory = blog_category.result;
      break;

    default:
      blogCategory = "current_affair";
      break;
  }
  return `/v2/posts/all?category_id=${blogCategory}`;
};
