import {
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  TablePagination,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import privateAxios from "../../axios/privateAxios";
import publicAxios from "../../axios/publicAxios";
import { catchError } from "../../helper/useError";
import { authAtom } from "../../state/atoms/authAtom";
import { toastAtom } from "../../state/atoms/toastAtom";
import ConfirmationModal from "../ConfirmationModal";
import Loader from "../Tools/Loader";

export const PostTable = () => {
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [deactivate, setDeactivate] = useState([]);
  const [, setShowToast] = useRecoilState(toastAtom);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 6,
    totalPage: 0,
    totalCount: 0,
  });

  const [loading, setLoading] = useState(true);
  const [allTypes, setAllTypes] = useState([]);
  const [openModal, setOpenModal] = useState({
    open: false,
    id: null,
    loading: false,
    type: null,
  });
  const [draft, setDraft] = useState(false);
  const [filter, setFilter] = useState({
    drafts: false,
    current_affairs: false,
    editorials: false,
    vacancies: false,
  });

  const handleCategory = (e) => {
    const { name, checked } = e.target;
    if (name === "drafts") {
      setFilter((pre) => ({
        ...pre,
        [name]: checked,
      }));
    } else {
      setFilter((pre) => ({
        drafts: filter.drafts,
        current_affairs: false,
        editorials: false,
        vacancies: false,
        [name]: checked,
      }));
    }
  };

  const handleChangePage = (event, page) => {
    setPagination({
      ...pagination,
      page: page + 1,
    });
  };

  const getTypes = async () => {
    try {
      const response = await publicAxios.get("v2/category/all?type=POST");
      let responseData = response.data;
      setAllTypes(responseData);
    } catch (error) {
      console.log("Error rr", error);
    }
  };

  const getInfo = async () => {
    let trueKeys = "";
    for (let key in filter) {
      if (filter[key] === true && key !== "drafts") {
        trueKeys = key;
      }
    }
    const blog_category = {
      current_affairs: 1,
      editorials: 2,
      vacancies: 3,
    };
    await privateAxios
      .get(
        `v2/posts?publish=${!draft ? "True" : "False"}&page=${
          pagination.page
        }&category_id=${
          blog_category[trueKeys] ? blog_category[trueKeys] : ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let responseData = res.data.data;
          const filterdActiveData = responseData.filter(
            (item) => item.publish === true
          );
          const filterDeactivateData = responseData.filter(
            (item) => item.publish === false
          );

          setActiveData(filterdActiveData);
          setDeactivate(filterDeactivateData);
          setPagination({
            ...pagination,
            page: res.data.current_page,
            pageSize: res.data.per_page,
            totalPage: res.data.total_page,
            totalCount: res.data.total_count,
          });
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        console.info("error", e);
      });
  };

  useEffect(() => {
    getTypes();
  }, []);

  useEffect(() => {
    getInfo();
  }, [filter, draft, pagination.page]);

  const closeModal = () => {
    setOpenModal({
      open: false,
      id: null,
      loading: false,
      type: null,
      objId: null,
    });
  };

  const handleActions = async (objId) => {
    try {
      setOpenModal((prevState) => ({ ...prevState, loading: true }));
      const make_type = openModal.type === "published" ? "unsubmit" : "submit";
      const make_text =
        openModal.type === "published" ? "unpublished" : "published";
      const url = `/v2/posts/${openModal.id}/${make_type}`;
      const response = await publicAxios.post(
        url,
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      );
      if (response.status === 200) {
        let removedItem;
        if (make_type === "unsubmit") {
          removedItem = activeData.find((obj) => obj.id === openModal.id);

          console.log(activeData, openModal);

          removedItem.publish = false;
          setDeactivate((prevState) => [...prevState, removedItem]);
          setActiveData((prevState) =>
            prevState.filter((obj) => obj.id !== openModal.id)
          );
        } else {
          removedItem = deactivate.find((obj) => obj.id === openModal.id);
          removedItem.publish = true;
          setActiveData((prevState) => [...prevState, removedItem]);
          setDeactivate((prevState) =>
            prevState.filter((obj) => obj.id !== openModal.id)
          );
        }
        setOpenModal({
          open: false,
          id: null,
          loading: false,
          type: null,
        });
      }
      setShowToast({
        status: true,
        type: "success",
        successMessage: `Post has been ${make_text} now!`,
      });
    } catch (error) {
      console.log("Error", error);
      const err = await catchError(error);
      setShowToast({
        status: true,
        type: "error",
        errorMessage: err,
      });
    }
  };

  const columns = [
    // {
    //   field: "rowNumber",
    //   headerName: "No",
    //   width: 70,
    //   renderCell: (params) => {
    //     const index = activeData.findIndex((row) => row.id === params.row.id);
    //     const count = index !== -1 ? index + 1 : "NA";
    //     return count;
    //   },
    // },
    {
      field: "title",
      headerName: "Title",
      width: 320,
    },
    {
      field: "published_on",
      headerName: "Post Date",
      width: 170,
    },
    {
      field: "posted_by",
      headerName: "Author/s",
      width: 200,
      renderCell: (params) => {
        const name = params.row?.posted_by?.first_name;
        return name || "NA";
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row.category || "NA";
        const blog_category = {
          1: "Current Affair",
          2: "Editorial",
          3: "Job",
          4: "Result",
        };
        return blog_category[currentRow];
      },
    },
    {
      field: "id",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row;

        return (
          <Stack direction="row" spacing={-3}>
            <Button
              onClick={() => {
                navigate("/u1NzT9Pq2s5/admin/post/editPost/" + currentRow.id);
              }}
            >
              <img src="/images/admin/edit.png" alt="" />
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "publish",
      headerName: "Current status",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row.publish;
        return (
          <Stack direction="row" spacing={-3}>
            <Button
              size="small"
              variant="contained"
              color={currentRow ? "primary" : "error"}
              onClick={() => {
                setOpenModal({
                  open: true,
                  id: params.id,
                  type: currentRow ? "published" : "unpublished",
                  loading: false,
                });
              }}
            >
              {openModal.loading && openModal.id === params.id ? (
                <CircularProgress
                  size={15}
                  style={{ color: "white", marginRight: "10px" }}
                />
              ) : null}
              {currentRow ? "Published" : "Unpublished"}
            </Button>
          </Stack>
        );
      },
    },
  ];

  // Otherwise filter will be applied on fields such as the hidden column id

  return (
    <>
      {loading && <Loader />}
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          p={2}
          border={1}
          borderColor="grey.300"
          alignItems="center"
          style={{ marginBottom: "15px", border: "none", background: "white" }}
        >
          <Stack direction="row" justifyContent="end">
            {/* Checkbox */}
            <FormControlLabel
              control={<Checkbox checked={draft} />}
              label="Drafts"
              name="drafts"
              onChange={(event) => setDraft(event.target.checked)}
            />
            {allTypes.map((category) => (
              <FormControlLabel
                key={category.id}
                control={
                  <Checkbox checked={filter[category.name.toLowerCase()]} />
                }
                name={
                  category.name === "Current Affairs"
                    ? "current_affairs"
                    : category.name.toLowerCase()
                }
                onChange={handleCategory}
                label={category.name}
              />
            ))}
            <Button
              variant="contained"
              onClick={() => {
                navigate("/u1NzT9Pq2s5/admin/post/addPost");
              }}
              size="small"
            >
              Create
            </Button>
          </Stack>
        </Stack>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.field}>{column.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => (
                    <TableCell key={column.field}>
                      {column.renderCell
                        ? column.renderCell({
                            row: { [column.field]: row?.[column.field] },
                            id: row?.id,
                          })
                        : row?.[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[pagination.pageSize]}
          component="div"
          count={pagination.totalCount}
          rowsPerPage={pagination.pageSize}
          page={pagination.page - 1}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* {data.length ? (
          <DataGrid
            rows={!draft ? activeData : deactivate}
            columns={columns}
            getRowId={(row) => row.id}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            style={{ border: "none", background: "white" }}
            initialState={{
              pagination: {
                paginationModel: pagination,
              },
            }}
            onPaginationModelChange={(page)=>setPagination(page)}
          />
        ) : (
          "No Record Found"
        )} */}
      </Box>
      {openModal.open ? (
        <ConfirmationModal
          message={`Are you sure you want to ${
            openModal.type === "published" ? "Deactivate" : "Active"
          } this post?`}
          closeModal={closeModal}
          handleActions={handleActions}
          data={openModal}
        />
      ) : null}
    </>
  );
};
